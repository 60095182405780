import React, { useState, useEffect } from 'react';
import { db, auth, collection, getDocs, query, orderBy, addDoc } from '../firebase';
import './Blog.css';
import Banner from '../components/Banner';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo512.png';
import finlawPic from '../assets/images/FinLawPicPanama.png';
import BlogEditor from '../components/BlogEditor';
import BlogPost from '../components/BlogPost';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogPosts = async () => {
    try {
      const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: new Date(doc.data().date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      }));
      setBlogPosts(posts);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  useEffect(() => {
    const checkFirebase = async () => {
      try {
        console.log('Firebase config:', {
          projectId: process.env.REACT_APP_PROJECT_ID,
          hasApiKey: !!process.env.REACT_APP_API_KEY,
          hasAuth: !!auth,
          hasDb: !!db,
          envVars: {
            hasProjectId: !!process.env.REACT_APP_PROJECT_ID,
            hasAuthDomain: !!process.env.REACT_APP_AUTH_DOMAIN,
            hasStorageBucket: !!process.env.REACT_APP_STORAGE_BUCKET,
            hasMessagingSenderId: !!process.env.REACT_APP_MESSAGING_SENDER_ID,
            hasAppId: !!process.env.REACT_APP_APP_ID,
            hasMeasurementId: !!process.env.REACT_APP_MEASUREMENT_ID
          }
        });
        
        if (!db || !auth) {
          throw new Error('Firebase not properly initialized');
        }

        const testRef = collection(db, 'test');
        const testDoc = await addDoc(testRef, {
          test: 'Connection test',
          timestamp: new Date()
        });
        console.log('Firebase connection successful, test doc ID:', testDoc.id);
      } catch (error) {
        console.error('Firebase connection error:', error);
      }
    };
    
    checkFirebase();
  }, []);

  return (
    <div className="blog-page">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>

      <div className="blog-content">
        <h1>Our Blog</h1>
        <BlogEditor onNewPost={fetchBlogPosts} />
        
        {loading ? (
          <div className="loading">Loading posts...</div>
        ) : (
          <div className="blog-posts-container">
            {blogPosts.map((post) => (
              <BlogPost key={post.id} post={post} />
            ))}
          </div>
        )}
      </div>

      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
