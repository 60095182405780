// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Views/HomePage';
import WhatWeDo from './Views/WhatWeDo';
import Insights from './Views/Insights';
import OurFirm from './Views/OurFirm';
import Blog from './Views/Blog';
import './firebase';
import Tools from './Views/Tools';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/our-firm" element={<OurFirm />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
