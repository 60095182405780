import React from 'react';
import './Footer.css';
import logo from '../assets/images/logo512.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="footer-sections">
          <div className="footer-column">
            <h3>CAREERS</h3>
            <ul>
              <li>Overview</li>
              <li>Featured Locations</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>RESOURCES</h3>
            <ul>
              <li>Client Logins</li>
              <li>Pressroom</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>OUR BUSINESSES</h3>
            <ul>
              <li>Investment Banking</li>
              <li>FI and Equities</li>
              <li><a href="#">Asset Management</a></li>
              <li><a href="#">Private Wealth Management</a></li>
              <li>Transaction Banking</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>CONNECT</h3>
            <div className="social-icons">
              <img src={logo} alt="LinkedIn" />
              <img src={logo} alt="Instagram" />
              <img src={logo} alt="YouTube" />
              <img src={logo} alt="X" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Blackridge Group. All rights reserved.</p>
        <ul className="footer-links">
          <li>Privacy and Cookies</li>
          <li>Your Privacy Choices</li>
          <li>Terms & Conditions</li>
          <li>Security & Fraud Awareness</li>
          <li>Regulatory Disclosures</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
