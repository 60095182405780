import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailSubject = encodeURIComponent('Contact Inquiry');
    const emailBody = encodeURIComponent(message);
    window.location.href = `mailto:contact@blackridgegroup.com?subject=${emailSubject}&body=${emailBody}`;
  };

  return (
    <div className="contact-section">
      <h2>Get in Touch</h2>
      <p>Have questions or need assistance? Send us a message.</p>
      <form onSubmit={handleSubmit}>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
          required
        />
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;

