import React from 'react';
import './Tools.css';
import Banner from '../components/Banner';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo512.png';

const Tools = () => {
  const toolsList = [
    {
      title: "DCF Financial Model",
      description: "Comprehensive Discounted Cash Flow model for company valuations with detailed projections and sensitivity analysis",
      fileType: "Excel",
      fileName: "dcf_financial_model.xlsx",
      icon: "💹"
    },
    {
      title: "Financial Model Template",
      description: "Standard financial model template for startup valuations",
      fileType: "Excel",
      fileName: "financial_model_template.xlsx",
      icon: "📊"
    },
    {
      title: "Due Diligence Checklist",
      description: "Comprehensive checklist for M&A due diligence",
      fileType: "PDF",
      fileName: "due_diligence_checklist.pdf",
      icon: "📋"
    },
    {
      title: "Investment Memo Template",
      description: "Professional template for investment memorandums",
      fileType: "Word",
      fileName: "investment_memo_template.docx",
      icon: "📝"
    }
  ];

  const handleDownload = (fileName) => {
    const fileUrl = `/downloads/${fileName}`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="tools-page">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/tools">Tools</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>
      <div className="tools-content">
        <h1>Financial Tools & Templates</h1>
        <div className="tools-container">
          {toolsList.map((tool, index) => (
            <div key={index} className="tool-card" onClick={() => handleDownload(tool.fileName)}>
              <div className="tool-icon">{tool.icon}</div>
              <h3>{tool.title}</h3>
              <p>{tool.description}</p>
              <span className="file-type">{tool.fileType}</span>
              <button className="download-btn">Download</button>
            </div>
          ))}
        </div>
      </div>
      <NewsletterCTA />
      <Contact />
      <Footer />
    </div>
  );
};

export default Tools;
