import React from 'react';
import './Insights.css';
import Banner from '../components/Banner';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo512.png';
import finlawPic from '../assets/images/FinLawPicPanama.png';

const Insights = () => {
  const articles = [
    {
      title: "2024 Financial Market Outlook",
      category: "Market Analysis",
      date: "March 15, 2024",
      summary: "An in-depth analysis of global market trends and predictions for the coming year.",
      readTime: "8 min read",
      image: finlawPic
    },
    {
      title: "ESG Investing: The New Normal",
      category: "Investment Strategy",
      date: "March 12, 2024",
      summary: "How environmental, social, and governance factors are reshaping investment decisions.",
      readTime: "6 min read",
      image: finlawPic
    },
    {
      title: "Regulatory Changes in Banking Sector",
      category: "Regulatory",
      date: "March 10, 2024",
      summary: "Key updates on banking regulations and their impact on financial institutions.",
      readTime: "5 min read",
      image: finlawPic
    }
  ];

  return (
    <div className="insights-page">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>
      
      <div className="insights-content">
        <h1>Latest Insights</h1>
        <div className="articles-container">
          {articles.map((article, index) => (
            <div key={index} className="article-card">
              <div className="article-image">
                <img src={article.image} alt={article.title} />
              </div>
              <div className="article-content">
                <div className="article-metadata">
                  <span className="article-category">{article.category}</span>
                  <span className="article-date">{article.date}</span>
                </div>
                <h2>{article.title}</h2>
                <p>{article.summary}</p>
                <div className="article-footer">
                  <span className="read-time">{article.readTime}</span>
                  <button className="read-more">Read More</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Insights;
