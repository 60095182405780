import React from 'react';
import './OurFirm.css';
import Banner from '../components/Banner';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo512.png';
import finlawPic from '../assets/images/FinLawPicPanama.png';

const OurFirm = () => {
  const firmInfo = {
    overview: {
      title: "Who We Are",
      description: "Blackridge Group is a leading financial and legal advisory firm headquartered in Panama City. We combine deep industry knowledge with specialized expertise in banking, investment management, and legal advisory services.",
    },
    stats: [
      { number: "15+", label: "Years of Experience" },
      { number: "$2B+", label: "Assets Under Management" },
      { number: "500+", label: "Successful Transactions" },
      { number: "50+", label: "Professional Experts" }
    ],
    leadership: [
      {
        name: "John Smith",
        position: "Chief Executive Officer",
        image: finlawPic
      },
      {
        name: "Sarah Johnson",
        position: "Chief Legal Officer",
        image: finlawPic
      },
      {
        name: "Michael Chen",
        position: "Head of Investment Banking",
        image: finlawPic
      }
    ]
  };

  return (
    <div className="our-firm-page">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>

      <div className="firm-content">
        <section className="overview-section">
          <h1>{firmInfo.overview.title}</h1>
          <p>{firmInfo.overview.description}</p>
        </section>

        <section className="stats-section">
          <div className="stats-container">
            {firmInfo.stats.map((stat, index) => (
              <div key={index} className="stat-card">
                <h2>{stat.number}</h2>
                <p>{stat.label}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="leadership-section">
          <h2>Our Leadership</h2>
          <div className="leadership-container">
            {firmInfo.leadership.map((leader, index) => (
              <div key={index} className="leader-card">
                <div className="leader-image">
                  <img src={leader.image} alt={leader.name} />
                </div>
                <h3>{leader.name}</h3>
                <p>{leader.position}</p>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default OurFirm;
