import React from 'react';
import './WhatWeDo.css';
import Footer from './Footer';
import Contact from './Contact';
import NewsletterCTA from './NewsletterCTA';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo512.png';
import Banner from '../components/Banner';

const WhatWeDo = () => {
  const services = [
    {
      title: "Investment Banking",
      description: "We provide strategic advice and innovative financing solutions to corporations, institutions, and governments worldwide.",
    },
    {
      title: "Asset Management",
      description: "Our asset management services help clients build and preserve wealth through tailored investment strategies.",
    },
    {
      title: "Legal Advisory",
      description: "Our team of experienced lawyers offers comprehensive legal advice on complex financial and corporate matters.",
    },
  ];

  return (
    <div className="what-we-do-page">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>
      <div className="what-we-do-content">
        <h1>What We Do</h1>
        <div className="services-container">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-newsletter-container">
        <NewsletterCTA />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default WhatWeDo;
