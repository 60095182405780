// src/Views/HomePage.js
import React, { useState } from 'react';
import './HomePage.css';

import TopFold from './TopFold.js';
import DealsMade from '../components/DealsMade';
import Footer from './Footer';
import NewsletterCTA from './NewsletterCTA';
import Contact from './Contact';

const HomePage = () => {
    const [isBlue, setIsBlue] = useState(false);

    return (
        <div className="cafe-homepage">
            <TopFold />
            <DealsMade />
            <NewsletterCTA />
            <Contact />
            <Footer />
        </div>
    );
};

export default HomePage;
