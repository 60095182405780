import React from 'react';
import './DealsMade.css';
import finlawPic from '../assets/images/FinLawPicPanama.png';

const DealsMade = () => {
  const deals = [
    {
      date: '2024 | Debt',
      title: 'Advising and structuring Syndicated Loan of $200M for Energy Company.',
      image: finlawPic,
    },
    {
      date: '2024 | Debt',
      title: 'Advising and structuring a $100M Loan for a Maritime Logistics Company.',
      image: finlawPic,
    },
    {
      date: '2023 | M&A',
      title: 'Advising and raising $200M in Debt and Equity for Packaging Company to acquire 3 competitors.',
      image: finlawPic,
    },
  ];

  return (
    <div className="deals-made">
      <h2>Deals</h2>
      <div className="deals-container">
        {deals.map((deal, index) => (
          <div key={index} className="deal-card">
            <div className="deal-image">
              <img src={deal.image} alt={`Deal ${index + 1}`} />
            </div>
            <div className="deal-content">
              <p className="deal-date">{deal.date}</p>
              <p className="deal-title">{deal.title}</p>
              <a href="#" className="learn-more">Learn More</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealsMade;
