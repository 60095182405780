import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BlogEditor.css';
import { db, collection, addDoc } from '../firebase';

const BlogEditor = ({ onNewPost }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !content || !author) {
      alert('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);
    try {
      const docRef = await addDoc(collection(db, 'blogPosts'), {
        title,
        content,
        author,
        date: new Date().toISOString(),
        readTime: `${Math.ceil(content.split(' ').length / 200)} min read`,
        summary: content.slice(0, 150) + '...',
        createdAt: new Date().getTime()
      });

      console.log('Blog post saved with ID:', docRef.id);
      setTitle('');
      setContent('');
      setAuthor('');
      
      if (onNewPost) {
        onNewPost();
      }
      
      alert('Blog post published successfully!');
    } catch (error) {
      console.error('Error saving blog post:', error);
      alert('Error publishing blog post');
    }
    setIsSubmitting(false);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image'
  ];

  return (
    <div className="blog-editor">
      <h2>Create New Blog Post</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Blog Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="blog-title-input"
        />
        <input
          type="text"
          placeholder="Author Name"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          className="blog-author-input"
        />
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
          placeholder="Write your blog post here..."
        />
        <button 
          type="submit" 
          className="publish-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Publishing...' : 'Publish Post'}
        </button>
      </form>
    </div>
  );
};

export default BlogEditor;
