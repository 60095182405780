import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <h1>Welcome to Blackridge</h1>
      </div>
    </div>
  );
};

export default Banner;

