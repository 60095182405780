import React, { useState, useEffect } from 'react';
import './NewsletterCTA.css';

const API_KEY = process.env.REACT_APP_MAILBLUSTER_KEY;
const API_URL = 'https://api.mailbluster.com/api/leads';

const NewsletterCTA = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const storedCooldownTime = localStorage.getItem('newsletterCooldown');
    if (storedCooldownTime) {
      const remainingTime = parseInt(storedCooldownTime) - Date.now();
      if (remainingTime > 0) {
        setCooldownTime(remainingTime);
        setIsSubmitted(true);
      } else {
        localStorage.removeItem('newsletterCooldown');
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime((prevTime) => {
          const newTime = prevTime - 1000;
          if (newTime <= 0) {
            clearInterval(timer);
            setIsSubmitted(false);
            localStorage.removeItem('newsletterCooldown');
            return 0;
          }
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldownTime]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': API_KEY
        },
        body: JSON.stringify({
          email: email,
          subscribed: true,
          doubleOptIn: false,
          overrideExisting: true
        })
      });

      if (!response.ok) {
        throw new Error('Failed to subscribe');
      }

      const data = await response.json();
      console.log('Subscription successful:', data);

      setIsSubmitted(true);
      setEmail('');

      const cooldownEndTime = Date.now() + 15000; // 15 seconds from now
      localStorage.setItem('newsletterCooldown', cooldownEndTime.toString());
      setCooldownTime(15000);
    } catch (err) {
      console.error('Error subscribing:', err);
      setError('Failed to subscribe. Please try again later.');
    }
  };

  const buttonText = isSubmitted
    ? cooldownTime > 0
      ? `Wait ${Math.ceil(cooldownTime / 1000)}s`
      : 'Success! 🎉'
    : 'Subscribe';

  return (
    <div className="newsletter-cta">
      <h2>Stay Informed</h2>
      <p>Subscribe to our newsletter for the latest insights and updates.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          disabled={isSubmitted}
        />
        <button type="submit" className={isSubmitted ? 'success' : ''} disabled={isSubmitted}>
          {buttonText}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default NewsletterCTA;
