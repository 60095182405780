import React, { useState } from 'react';
import './BlogPost.css';

const BlogPost = ({ post }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="blog-card">
        <div className="blog-post-content">
          <div className="blog-metadata">
            <span className="blog-author">By {post.author}</span>
            <span className="blog-date">{post.date}</span>
          </div>
          <h2>{post.title}</h2>
          <p>{post.summary}</p>
          <div className="blog-footer">
            <span className="read-time">{post.readTime}</span>
            <button 
              className="read-more"
              onClick={() => setIsModalOpen(true)}
            >
              Read More
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="blog-modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="blog-modal" onClick={e => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setIsModalOpen(false)}>×</button>
            <div className="blog-modal-content">
              <div className="blog-metadata">
                <span className="blog-author">By {post.author}</span>
                <span className="blog-date">{post.date}</span>
              </div>
              <h2>{post.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPost; 