// TopFold.js
import React from 'react';
import { Link } from 'react-router-dom';
import './TopFold.css';
import logo from '../assets/images/logo512.png';

import Banner from '../components/Banner';

function TopFold() {
  return (
    <div className="header">
      <Banner />
      <nav className="navbar">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li><Link to="/what-we-do">What We Do</Link></li>
          <li><Link to="/insights">Insights</Link></li>
          <li><Link to="/our-firm">Our Firm</Link></li>
          <li><Link to="/tools">Tools</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <button className="client-login">Client Login</button>
      </nav>
      <div className="hero-section">
        <div className="hero-content">
          <h1>Strategic Advisory. Tangible Results.</h1>
          <p>
            Every day, we leverage sharp expertise, insights, and relatioships to deliver exceptional solutions.
            Our focus is to empower clients by combining financial and 
            legal advisory to overcome challenges, unlock opportunities,
            and achieve superior results.
          </p>
          <button className="cta-button">See Our Client Impact</button>
        </div>
      </div>
    </div>
  );
}

export default TopFold;
